<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card tile flat>
        <v-card-title>
          <span class="error--text font-weight-bold">
            <v-icon class="error--text font-weight-bold"
              >mdi-information-outline</v-icon
            >
            {{ $t('warn') }}
          </span>
        </v-card-title>
        <v-col>
          <v-card-title> {{data.text}} </v-card-title>
        </v-col>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            @click="dialog = false"
            color="error"
            outlined
            x-large
            class="mr-2 btn"
            >{{ $t('cancel') }}</v-btn
          >
          <v-btn
            @click="confirm(data.id)"
            color="primary"
            x-large
            class="mr-2 btn"
            >{{ $t('confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      data: {
        id: "",
        text: "",
        code: "",
      },
    };
  },

  computed: {
    target() {
      return this.data.code;
    },
  },

  methods: {
    confirm(id) {
      this.$service.eventBus.emit(this.target, {
        id: id,
        code: this.target,
      });

      this.$eventBus.$emit(this.target, {
        id: id,
        code: this.target,
      });

      this.dialog = false;
    },
  },

  created() {
    this.$eventBus.$on("confirm", (data) => {
      this.dialog = true;
      this.data = data;
    });

    this.$service.eventBus.on("confirm", (data) => {
      this.dialog = true;
      this.data = data;
    });
  },
};
</script>
